import React from 'react';
import Link from "gatsby-link";

function Locationgcccategory({data, setlocationsState, locationsState}) {

    const handleClick = value => () => {
        setlocationsState(`tab-${value}`);
    };
    return (
        <>
            {data && data ? data.map((value, i) => (
                <div
                    className={locationsState === `tab-${value.id}` ? 'main_category_box active' : 'main_category_box'}
                    onClick={handleClick(value.id)}>
                    <Link to="">
                        {value.name}
                        <span>{value.description}</span>
                    </Link>
                </div>
            )) : ''}
        </>
    );
}

export default Locationgcccategory;